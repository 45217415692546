<template>
    <!--begin::Menu-->
    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <!--begin::Avatar-->
                <div class="symbol symbol-50px me-5">
                    <img alt="Logo" src="/media/avatars/blank.png"/>
                </div>
                <!--end::Avatar-->

                <!--begin::Username-->
                <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                        {{ $root.currentUser.firstname + ' ' + $root.currentUser.lastname }}
                        <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{{ $root.currentUser.group ? $root.currentUser.group.name : ''}}</span>
                    </div>
                    <a :href="'mailto:'+ $root.currentUser.email" class="fw-bold text-muted text-hover-primary fs-7">{{ $root.currentUser.email }}</a>
                </div>
                <!--end::Username-->
            </div>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator my-2"></div>
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <!--<div class="menu-item px-5">
            <router-link to="/profile/overview" class="menu-link px-5">
                My Profile
            </router-link>
        </div>-->
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <!--<div class="menu-item px-5">
           <router-link to="/profile/overview" class="menu-link px-5">
               <span class="menu-text">My Projects</span>
               <span class="menu-badge">
                   <span class="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
               </span>
           </router-link>
       </div>-->
       <!--end::Menu item-->

        <!--begin::Menu item-->
        <!--<div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start" data-kt-menu-flip="center, top">
            <router-link to="/profile/overview" class="menu-link px-5">
                <span class="menu-title">My Subscription</span>
                <span class="menu-arrow"></span>
            </router-link>

            <div class="menu-sub menu-sub-dropdown w-175px py-4">
                <div class="menu-item px-3">
                    <router-link to="/profile/overview" class="menu-link px-5">
                        Referrals
                    </router-link>
                </div>
                <div class="menu-item px-3">
                    <router-link to="/profile/overview" class="menu-link px-5">
                        Billing
                    </router-link>
                </div>
                <div class="menu-item px-3">
                    <router-link to="/profile/overview" class="menu-link px-5">
                        Payments
                    </router-link>
                </div>

                <div class="menu-item px-3">
                    <router-link to="/profile/overview" class="menu-link d-flex flex-stack px-5">
                        Statements

                        <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="View your statements"></i>
                    </router-link>
                </div>

                <div class="separator my-2"></div>

                <div class="menu-item px-3">
                    <div class="menu-content px-3">
                        <label class="form-check form-switch form-check-custom form-check-solid">
                            <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications"/>
                            <span class="form-check-label text-muted fs-7">
                                Notifications
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>-->
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <!--<div class="menu-item px-5">
            <router-link to="/profile/overview" class="menu-link px-5">
                My Statements
            </router-link>
        </div>-->
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <!--<div class="separator my-2"></div>-->
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <div class="menu-item px-5 my-1">
            <router-link to="/account/overview" class="menu-link px-5">
                {{ $t('userMenu.accountSettings') }}
            </router-link>
        </div>

        <div class="menu-item px-5">
            <a @click="signOut()" class="menu-link px-5"> {{ $t('userMenu.signOut') }} </a>
        </div>
    </div>
    <!--end::Menu-->
</template>

<script>
import {Actions} from "@/store/enum/StoreEnums";

export default {
    name: "kt-user-menu",
    components: {},
    data() {
        return {
            countries: {
                tr: {
                    flag: "/media/flags/turkey.svg",
                    name: "Türkçe",
                },
                en: {
                    flag: "/media/flags/united-states.svg",
                    name: "English",
                }
            }
        }
    },
    computed: {
        currentLanguageLocale() {
            return this.countries[this.$i18n.locale];
        }
    },
    methods: {
        signOut() {
            this.$store.dispatch(Actions.LOGOUT).then(() => this.$router.push({ path: "/login" }));
        },
        setLang(lang) {
            localStorage.setItem("lang", lang);
            this.$i18n.locale = lang;
        },
        currentLanguage(lang) {
            return this.$i18n.locale === lang;
        }
    }
};
</script>